.news {
  @media (max-width: 767.98px) {
    margin-bottom: 64px;
  }

  &__title {
    margin-bottom: 32px;
  }
  &__link-title {
    font-family: "Museo Cyrillic Bold";
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    color: var(--blue) !important;
    padding-bottom: 1px;
    border-bottom: 1px solid var(--blueLight);
    @media (max-width: 767.98px) {
      font-size: 32px;
    }
  }

  &__link-title:hover {
    border-color: var(--blue);
  }
  &__link-title:active {
    color: var(--orange) !important;
    border-color: transparent;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 48px;
    row-gap: 88px;
    @media (max-width: 1199.98px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 48px;
    }
    @media (max-width: 767.98px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 8px;
      row-gap: 32px;
      margin-bottom: 32px;
    }
  }
}

.news-card {
  &__link > * {
    margin-bottom: 12px;
    @media (max-width: 767.98px){
      margin-bottom: 8px;
    }
  }
  &__image-block {
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  &__coming {
    position: absolute;
    bottom: 4px;
    left: 0;
    padding: 0 12px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--white);
    background-color: var(--blue);
    border-radius: 0 10px 0 10px;
  }

  &__attention {
    position: absolute;
    bottom: 4px;
    left: 0;
    padding: 0 12px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--white);
    background-color: var(--red);
    border-radius: 0 10px 0 10px;
  }

  &__new {
    position: absolute;
    bottom: 4px;
    left: 0;
    padding: 0 12px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--white);
    background-color: var(--green);
    border-radius: 0 10px 0 10px;
  }

  &__contest {
    position: absolute;
    bottom: 4px;
    left: 0;
    padding: 0 12px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--white);
    background-color: var(--violet);
    border-radius: 0 10px 0 10px;
  }


  &__title span {
    padding-bottom: 1px;
    border-bottom: 1px solid var(--blackLight);
  }
  &__title span:hover {
    border-color: var(--black);
  }
  &__title span:active {
    color: var(--orange) !important;
    border-color: transparent;
  }
  &__date {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--gray);
    @media (max-width: 767.98px){
      font-size: 14px;
    }
  }
}
