.button {
  display: inline-block;
  padding: 12px 22px;
  color: var(--green);
  font-family: "Museo Cyrillic Bold";
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background-color: var(--white);
  border-radius: 10px;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  border: 2px solid var(--greenLight);

  &:hover {
    border-color: var(--green);
    transition: 0.5s;
  }
  &:active {
    color: var(--orange);
    border-color: var(--orange);
    transition: 0.5s;
  }

  &_green {
    padding: 14px 24px;
    border: none;
    color: var(--white) !important;
    background: linear-gradient(180deg, #30C43F 0%, #2C9A37 100%);

  }
  
  &_green:hover {
    background: linear-gradient(180deg, #30c33f 0%, #30c33f 100%);
    transition: 0.5s;
  }
  &_green:active {
    background: linear-gradient(180deg, #FF9F29 0%, #FF9F29 100%);
    transition: 0.5s;
  }

  &_blue {
    color: var(--blue);
    border: 2px solid var(--blueLight);
  }
  &_blue:hover {
    border-color: var(--blue);
  }
  &_blue:active {
    color: var(--orange);
    border-color: var(--orange);
    transition: 0.5s;
  }
  
}



