.catalog {
  margin-bottom: 150px;
  @media (max-width: 767.98px) {
    margin-bottom: 48px;
  }
  &__title {
    margin-bottom: 64px;
    @media (max-width: 767.98px) {
      margin-bottom: 24px;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
    @media (max-width: 1600px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767.98px) {
      gap: 8px;
    }
  }
  &_page-catalog &__body{
    @media (max-width: 767.98px) {
     display: flex;
     flex-direction: column;
     gap: 8px;
    }
  }
  &__item {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    @media (max-width: 767.98px) {
      height: 160px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: end;
    }
  }

  &_page-catalog &__item {
    @media (max-width: 767.98px) {
      height: 100%;
      text-align: left;
    }
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    object-fit: contain;
    @media (max-width: 1100px) {
      opacity: 0.1;
    }
    @media (max-width: 767.98px) {
      position: relative;
      opacity: 1;
      height: 100%;
    }
  }
  &_page-catalog &__image{
    @media (max-width: 767.98px) {
    position: absolute;
    top: 0;
    right: 0;
    }
  }

  &__body-item {
    position: relative;
    z-index: 1;
    padding: 48px;
    @media (max-width: 767.98px) {
      padding: 0px;
    }
  }
  &__body-item_min {
    padding: 30px 48px;
    @media (max-width: 767.98px) {
      padding: 0 0 40px;
    }
  }
  &_page-catalog &__body-item {
    @media (max-width: 767.98px) {
      padding: 40px 16px 56px;
    }
  }
  &_page-catalog &__body-item_min {
    @media (max-width: 767.98px) {
      padding: 34px 16px;
    }
  }
  &__body-item > *:not(:last-child) {
    margin-bottom: 24px;
    @media (max-width: 767.98px) {
      margin-bottom: 32px;
    }
  }
  &_page-catalog &__body-item > *:not(:last-child) {
    @media (max-width: 767.98px) {
      margin-bottom: 20px;
    }
  }
  &__link-title {
    padding-bottom: 2px;
    border-bottom: 1px solid var(--blackLight);
  }
  &__link-title:hover {
    border-color: var(--black);
  }
  &__link-title:active {
    color: var(--orange);
    border-color: transparent;
  }
  &__link {
    display: block;
    @media (max-width: 767.98px) {
      display: none;
    }
  }

  &_page-catalog &__link {
    @media (max-width: 767.98px) {
      display: block;
    }
  }
  &__link-text {
    font-family: "Museo Cyrillic Medium";
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--blue) !important;
    border-bottom: 1px solid var(--blueLight);
    margin-right: 8px;
  }

  &_page-catalog &__link-text {
    @media (max-width: 767.98px) {
      font-family: 'Museo Cyrillic Medium';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
  }
  &__link-text:hover {
    border-color: var(--blue);
  }
  &__link-text:active {
    color: var(--orange) !important;
    border-color: transparent;
  }

  &__counter {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--gray);
  }
  &_page-catalog &__counter {
    @media (max-width: 767.98px){
      font-size: 10px;
      line-height: 16px;
    }
  }
}
