:root {
  --white: #ffffff;
  --whiteLight: rgba(255, 255, 255, 0.3);
  --white2: #f4f4f4; 
  --black: #000000;
  --blackLight: rgba(0, 0, 0, 0.15);
  --gray: #9b9b9b;
  --grayLight: rgba(155, 155, 155, 0.3);;
  --green: #30c33f;
  --greenLight: rgba(48, 195, 63, 0.3);
  --blue: #1f79cb;
  --blueLight: rgba(31, 121, 203, 0.3);
  --orange: #FF9F29;
  --red: #ff4b3a;
  --redLight: rgba(253, 0, 0, 0.05);
  --violet: #9d20ff;
  --blueBG: #e8f4ff;
  --greenBG: #e8ffee;
  --pinkBG: #ffe8e8;
  --yellowBG: #ffffe8;
}

body {
  font-family: "Museo Cyrillic Medium";
  font-style: normal;
  font-size: 16px;
  line-height: normal;
  color: var(--black);
}

body._lock {
  overflow: hidden;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--white);
}

.page__main {
  flex-grow: 1;
}

.container {
  max-width: 1610px;
  padding: 0px 15px;
  margin: 0 auto;
}

.visually-hidden {
  display: none !important;
}
  /*mobile*/
@media (min-width: 768px) {
  .visible-mobile {
    display: none !important;
  }
}
  /*desktop*/
@media (max-width: 767.98px) {
  .hidden-mobile {
    display: none !important;
  }
}

h1,
.h1 {
  font-family: "Museo Cyrillic Bold";
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}

h2,
.h2 {
  font-family: "Museo Cyrillic Bold";
  font-size: 48px;
  font-weight: 700;
  line-height: 1;

  @media (max-width: 767.98px) {
    font-size: 32px;
    line-height: 1;
  }
}

h3,
.h3 {
  font-family: "Museo Cyrillic Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
  @media (max-width: 991.98px) {
    font-size: 28px;
  }
  @media (max-width: 767.98px) {
    font-size: 16px;
    line-height: 1.5;
  }
}

h4,
.h4 {
  @media (max-width: 767.98px) {
  }
}

.bg-yellow {
  background-color: var(--yellowBG);
}
.bg-pink {
  background-color: var(--pinkBG);
}
.bg-blue {
  background-color: var(--blueBG);
}
.bg-green {
  background-color: var(--greenBG);
}

.accent-color {
  background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.accent-italic-color {
  background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic;
}

