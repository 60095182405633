.blog {
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 767.98px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  &__container {
    position: relative;
    @media (max-width: 767.98px) {
      padding: 0px;
    }
  }

  &__body {
    position: relative;
    padding: 80px 70px 50px 48px;
    display: grid;
    grid-template: repeat(2, auto) / 37% 1fr 27%;
    grid-template-areas: "blog-left blog-center blog-right" "foot-left foot-center foot-right";
    column-gap: 20px;
    background: var(--grayLight);
    @media (max-width: 1199.98px) {
      grid-template: repeat(3, auto) / repeat(2, auto);
      grid-template-areas: "blog-left blog-right" "foot-left foot-right" "foot-center foot-right";
      column-gap: 40px;
    }
    @media (max-width: 767.98px) {
      display: flex;
      flex-direction: column;
      padding: 0px 15px 64px 15px;
    }
  }
  &__title a {
    border-bottom: 1px solid var(--blackLight);
  }

  &__title a:hover {
    border-color: var(--black);
  }
  &__title a:active {
    color: var(--orange) !important;
    border-color: transparent;
  }
  &__left {
    grid-area: blog-left;
  }

  &__left > * {
    margin-bottom: 48px;
    @media (max-width: 767.98px) {
      margin-bottom: 40px;
    }
  }
  &__left > *:not(:first-child) {
    max-width: 372px;
    @media (max-width: 767.98px) {
      max-width: 100%;
    }
  }
  &__image-left-mobile {
    position: absolute;
    bottom: -30px;
    left: -15px;
  }
  &__center {
    grid-area: blog-center;
    position: absolute;
    top: -120px;
    left: -50px;
    padding-right: 120px;
    @media (max-width: 1400px) {
      top: 0px;
      left: 0px;
      padding-right: 60px;
    }
    @media (max-width: 1199.98px) {
      display: none;
    }
  }
  &__center img {
    width: 100%;
    height: 100%;
  }
  &__center-text {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    width: 144px;
    position: absolute;
    bottom: 40px;
    right: 120px;
    @media (max-width: 1400px) {
      bottom: 40px;
      right: 0px;
    }
  }
  &__right {
    grid-area: blog-right;
    position: relative;
    @media (max-width: 767.98px) {
      margin-right: 100px;
    }
  }

  &__image-right-mobile {
    position: absolute;
    bottom: -80px;
    right: -115px;
  }
  &__right > * {
    margin-bottom: 48px;
    @media (max-width: 767.98px) {
      margin-bottom: 40px;
    }
  }

  &__text {
    font-family: "Museo Cyrillic Medium";
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    border-bottom: 1px solid var(--blackLight);
    @media (max-width: 991.98px) {
      font-size: 24px;
    }
  }

  &__text:hover {
    border-color: var(--black);
  }
  &__text:active {
    color: var(--orange) !important;
    border-color: transparent;
  }
  &__text_pl {
    @media (max-width: 767.98px) {
      padding-left: 65px;
      position: relative;
    }
  }
  &__foot-left {
    grid-area: foot-left;
    max-width: 320px;
    @media (max-width: 1199.98px) {
      margin-bottom: 48px;
    }
    @media (max-width: 767.98px) {
      margin-bottom: 40px;
      max-width: 100%;
    }
  }

  &__foot-center {
    grid-area: foot-center;
    max-width: 320px;
    @media (max-width: 767.98px) {
      margin-bottom: 40px;
      margin-left: 117px;
      max-width: 100%;
    }
  }

  &__foot-right {
    grid-area: foot-right;
    @media (max-width: 1199.98px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 767.98px) {
      justify-content: end;
    }
  }

  &__button {
    display: inline-block;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--blue);
    border-radius: 50%;
    color: var(--white) !important;
    font-family: "Museo Cyrillic Medium";
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    @media (max-width: 767.98px) {
      width: 200px;
      height: 200px;
      font-size: 24px;
    }
  }
  &__button span {
    border-bottom: 1px solid var(--whiteLight);
  }

  &__button:hover span,
  &__button:focus span {
    border-bottom: 1px solid var(--white);
  }
  &__button:active {
    background-color: var(--orange) !important;
  }
  &__button:active span {
    border-bottom: 1px solid var(--orange);
  }

  &__image-left {
    position: absolute;
    top: 370px;
    left: -160px;
  }
  &__image-bottom {
    position: absolute;
    bottom: 0;
    left: 47%;
    @media (max-width: 991.98px) {
      left: 35%;
    }
  }
  &__wrapper-image {
    background: var(--grayLight);
  }
  &__image-top-mobile {
    width: 100%;
    margin-bottom: 30px;
  }

  &__image-bottom-mobile {
    position: absolute;
    bottom: -50px;
    left: 0;
    @media (max-width: 400px) {
      bottom: 0px;
    }
  }
}
