.footer {
  &__container {
    position: relative;
    padding: 80px 72px 100px;
    background-color: var(--black);
    border-radius: 10px;
    color: var(--white);
    @media (max-width: 1199.98px) {
      padding: 50px 50px 150px;
    }
    @media (max-width: 767.98px) {
      padding: 48px 16px 64px;
      border-radius: 0px;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 1199.98px) {
      display: grid;
      grid-template: repeat(2, auto) / repeat(3, 1fr);
      grid-template-areas: "section-menu section-second-menu section-about" "section-contacts-m section-contacts-sp section-contacts-sp";
      column-gap: 30px;
      row-gap: 50px;
    }
    @media (max-width: 767.98px) {
      grid-template: repeat(4, auto) / repeat(2, 1fr);
      grid-template-areas:
        "section-menu section-second-menu"
        "section-menu section-about"
        "section-contacts-m section-contacts-m"
        "section-contacts-sp section-contacts-sp";
      column-gap: 30px;
      row-gap: 30px;
      margin-bottom: 40px;
    }
  }

  &__section {
  }
  &__section-menu {
    grid-area: section-menu;
  }
  &__section-second-menu {
    grid-area: section-second-menu;
  }
  &__section-about {
    grid-area: section-about;
  }
  &__section-contacts-m {
    grid-area: section-contacts-m;
    @media (max-width: 767.98px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &__section-contacts-sp {
    grid-area: section-contacts-sp;
  }

  &__title {
    font-family: "Museo Cyrillic Bold";
    font-size: 36px;
    line-height: 1.1;
    text-align: left;
    margin-bottom: 16px;
    white-space: nowrap;
    @media (max-width: 767.98px) {
      font-family: "Museo Cyrillic Medium";
      font-size: 24px;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 16px;
    @media (max-width: 767.98px) {
      font-size: 14px;
    }
  }
  &__contacts_page-menu-mobile {
    gap: 0;
  }
  &__contacts_page-menu-mobile:first-child {
    padding-bottom: 20px;
  }
  &__address {
    font-family: "Inter";
    font-weight: 400;
    line-height: 24px;
  }

  &__wrapper-email {
    font-family: "Museo Cyrillic Medium";
    font-weight: 500;
    line-height: 1.5;
  }
  &__email {
    padding-bottom: 2px;
    color: var(--blue) !important;
    border-bottom: 1px solid var(--blueLight);
  }
  &__email:hover{
    border-bottom: 1px solid var(--blue);
  }
  &__email:active {
    color: var(--orange) !important;
    border-bottom: none;
  }
  &__wrapper-telephone {
    font-family: "Museo Cyrillic Bold";
    font-weight: 700;
    line-height: 1.5;
  }
  &__telephone {
    padding-bottom: 2px;
    color: var(--green) !important;
    border-bottom: 1px solid var(--greenLight);
  }
  &__telephone:hover{
    border-bottom: 1px solid var(--green);
  }
  &__telephone:active {
    color: var(--orange) !important;
    border-bottom: none;
  }
  &__foot {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--whiteLight);
    @media (max-width: 1199.98px) {
      bottom: 50px;
    }
    @media (max-width: 767.98px) {
      position: relative;
      bottom: 0;
      text-align: left;
    }
  }
}
