@import url("https://fonts.googleapis.com/css?family=Inter:regular,500,700&display=swap");

@font-face {
  font-family: "Museo Cyrillic Medium";
  src: local("Museo Cyrillic Medium"),
    url("../fonts/Museo Cyrl 500.otf") format("otf"),
    url("../fonts/Museo Cyrl 500.ttf") format("ttf"),
    url("../fonts/Museo Cyrl 500.woff") format("woff"),
    url("../fonts/Museo Cyrl 500.woff2") format("woff2");
}

@font-face {
  font-family: "Museo Cyrillic Medium";
  src: local("Museo Cyrillic Medium"),
    url("../fonts/Museo Cyrl 500.otf") format("otf"),
    url("../fonts/Museo Cyrl 500.ttf") format("ttf"),
    url("../fonts/Museo Cyrl 500.woff") format("woff"),
    url("../fonts/Museo Cyrl 500.woff2") format("woff2");
}

@font-face {
  font-family: "Museo Cyrillic Bold";
  src: local("Museo Cyrillic Bold"),
    url("../fonts/Museo Cyrl 700.otf") format("otf"),
    url("../fonts/Museo Cyrl 700.ttf") format("ttf"),
    url("../fonts/Museo Cyrl 700.woff") format("woff"),
    url("../fonts/Museo Cyrl 700.woff2") format("woff2");
}

@font-face {
  font-family: "Museo Cyrillic Medium Italic";
  src: local("Museo Cyrillic Medium Italic"),
    url("../fonts/Museo Cyrl 500 Italic.otf") format("otf"),
    url("../fonts/Museo Cyrl 500 Italic.ttf") format("ttf"),
    url("../fonts/Museo Cyrl 500 Italic.woff") format("woff"),
    url("../fonts/Museo Cyrl 500 Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Museo Cyrillic Bold Italic";
  src: local("Museo Cyrillic Bold Italic"),
    url("../fonts/Museo Cyrl 700 Italic.otf") format("otf"),
    url("../fonts/Museo Cyrl 700 Italic.ttf") format("ttf"),
    url("../fonts/Museo Cyrl 700 Italic.woff") format("woff"),
    url("../fonts/Museo Cyrl 700 Italic.woff2") format("woff2");
}




