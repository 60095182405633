.icon {
  width: 24px;
  height: 24px;
  &_search{
    width: 20px;
    height: 20px;
    fill: var(--blue);  
  }
  &_user-button-icon{
    fill: var(--black); 
  }
  &_user-button-icon-mobile{
    fill: var(--white); 
  }
  &_menu-mobile-open{
    fill: var(--white); 
  }
  &_basket-mobile{
    fill: var(--white); 
  }
  &_menu-mobile-close{
    fill: var(--white); 
  }
  &_menu-mobile-arrow{
    stroke: var(--white);
    fill: transparent;
    width: 10px;
    height: 6px;
  }
  &_crumble{
    width: 17px;
    height: 8px;
  }
}
