.header {
  padding: 16px 0 0 0;
  margin-bottom: 50px;
  @media (max-width: 767.98px) {
    padding: 8px 0 0 0;
    margin-bottom: 0px;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    margin-bottom: 16px;
    @media (max-width: 991.98px) {
      display: grid;
      grid-template: repeat(2, 1fr) / repeat(2, 1fr);
      grid-template-areas: "contacts contacts" "second-menu button";
      justify-items: center;
      align-items: center;
    }
    @media (max-width: 767.98px) {
      display: flex;
      margin-bottom: 8px;
    }
  }
  &__second-menu {
    grid-area: second-menu;
    justify-self: start;
  }
  &__contacts {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-area: contacts;
  }
  &__contacts_mobile a {
    font-size: 16px;
  }
  &__item-contacts {
    display: flex;
    gap: 8px;
    padding: 5px 12px;
  }
  &__link-contacts {
    font-family: "Museo Cyrillic Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 143%;
    color: var(--green) !important;
    border-bottom: 1px solid var(--greenLight);
  }
  &__link-contacts:hover {
    border-bottom: 1px solid var(--green);
  }
  &__link-contacts:active {
    color: var(--orange) !important;
    border-bottom: none;
  }
  &__wraper-button {
    grid-area: button;
    justify-self: end;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 48px;
    margin-bottom: 24px;
    @media (max-width: 1199.98px) {
      display: grid;
      grid-template: repeat(2, 1fr) / repeat(2, 1fr);
      grid-template-areas: "logo user-buttons" "search-form search-form";
      gap: 10px;
    }
    @media (max-width: 767.98px) {
      display: grid;
      grid-template: repeat(2, 1fr) / repeat(3, 1fr);
      grid-template-areas: "menu-mobile logo basket-mobile" "search-form search-form search-form";
      gap: 8px;
      align-items: center;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    grid-area: logo;

    @media (max-width: 767.98px) {
      min-width: 182px;
    }
  }
  &__logo img {
    @media (max-width: 767.98px) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__menu-mobile-open {
    grid-area: menu-mobile;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    background: linear-gradient(225deg, #1f79cb 0%, #1a609f 100%);
    cursor: pointer;
    color: var(--white);
  }
  &__menu-mobile-open:active {
    background: var(--blue);
  }

  &__basket-mobile {
    grid-area: basket-mobile;
    justify-self: end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    cursor: pointer;
    background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
    color: var(--white);
  }

  &__basket-mobile:active {
    background: var(--orange);
  }

  &__search-form {
    flex-grow: 1;
    background-color: var(--white2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-radius: 10px;
    gap: 24px;
    grid-area: search-form;
  }
  &__input-form {
    flex-grow: 1;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
  }

  &__input-form::placeholder {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
  }

  &__search-button {
    display: flex;
    align-items: center;
    justify-content: center;
     cursor: pointer;
  }

  &__user-buttons {
    display: flex;
    gap: 32px;
    grid-area: user-buttons;
    justify-self: end;
  }
  &__user-button-link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__user-button-label {
    position: absolute;
    top: -5px;
    right: 15px;
    width: 28px;
    height: 20px;
    padding: 2px 6px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--white);
    border-radius: 12px;
    background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
  }
  &__user-button-label_mobile {
    top: 8px;
    right: 5px;
    width: 19px;
    height: 12px;
    background: var(--blue);
    font-size: 8px;
    padding: 2px 4px;
    line-height: 8px;
  }
  &__user-button-text {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
  }

  &__user-button-link:hover .icon_user-button-icon {
    fill: var(--blue);
  }

  &__user-button-link:hover &__user-button-text {
    color: var(--blue);
  }
  &__user-button-link:active .icon_user-button-icon {
    fill: var(--orange);
  }

  &__user-button-link:active &__user-button-text {
    color: var(--orange);
  }

  &__foot {
    background: var(--blue);
    border-radius: 10px;
    padding: 12px;
  }
}
