/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Inter:regular,500,700&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu,
ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*-----------------------------*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: inherit;
}

input,
button,
textarea,
select,
option {
  font-family: inherit;
  all: unset;
}

@font-face {
  font-family: "Museo Cyrillic Medium";
  src: local("Museo Cyrillic Medium"), url("../fonts/Museo Cyrl 500.otf") format("otf"), url("../fonts/Museo Cyrl 500.ttf") format("ttf"), url("../fonts/Museo Cyrl 500.woff") format("woff"), url("../fonts/Museo Cyrl 500.woff2") format("woff2");
}
@font-face {
  font-family: "Museo Cyrillic Medium";
  src: local("Museo Cyrillic Medium"), url("../fonts/Museo Cyrl 500.otf") format("otf"), url("../fonts/Museo Cyrl 500.ttf") format("ttf"), url("../fonts/Museo Cyrl 500.woff") format("woff"), url("../fonts/Museo Cyrl 500.woff2") format("woff2");
}
@font-face {
  font-family: "Museo Cyrillic Bold";
  src: local("Museo Cyrillic Bold"), url("../fonts/Museo Cyrl 700.otf") format("otf"), url("../fonts/Museo Cyrl 700.ttf") format("ttf"), url("../fonts/Museo Cyrl 700.woff") format("woff"), url("../fonts/Museo Cyrl 700.woff2") format("woff2");
}
@font-face {
  font-family: "Museo Cyrillic Medium Italic";
  src: local("Museo Cyrillic Medium Italic"), url("../fonts/Museo Cyrl 500 Italic.otf") format("otf"), url("../fonts/Museo Cyrl 500 Italic.ttf") format("ttf"), url("../fonts/Museo Cyrl 500 Italic.woff") format("woff"), url("../fonts/Museo Cyrl 500 Italic.woff2") format("woff2");
}
@font-face {
  font-family: "Museo Cyrillic Bold Italic";
  src: local("Museo Cyrillic Bold Italic"), url("../fonts/Museo Cyrl 700 Italic.otf") format("otf"), url("../fonts/Museo Cyrl 700 Italic.ttf") format("ttf"), url("../fonts/Museo Cyrl 700 Italic.woff") format("woff"), url("../fonts/Museo Cyrl 700 Italic.woff2") format("woff2");
}
:root {
  --white: #ffffff;
  --whiteLight: rgba(255, 255, 255, 0.3);
  --white2: #f4f4f4;
  --black: #000000;
  --blackLight: rgba(0, 0, 0, 0.15);
  --gray: #9b9b9b;
  --grayLight: rgba(155, 155, 155, 0.3);
  --green: #30c33f;
  --greenLight: rgba(48, 195, 63, 0.3);
  --blue: #1f79cb;
  --blueLight: rgba(31, 121, 203, 0.3);
  --orange: #FF9F29;
  --red: #ff4b3a;
  --redLight: rgba(253, 0, 0, 0.05);
  --violet: #9d20ff;
  --blueBG: #e8f4ff;
  --greenBG: #e8ffee;
  --pinkBG: #ffe8e8;
  --yellowBG: #ffffe8;
}

body {
  font-family: "Museo Cyrillic Medium";
  font-style: normal;
  font-size: 16px;
  line-height: normal;
  color: var(--black);
}

body._lock {
  overflow: hidden;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--white);
}

.page__main {
  flex-grow: 1;
}

.container {
  max-width: 1610px;
  padding: 0px 15px;
  margin: 0 auto;
}

.visually-hidden {
  display: none !important;
}

/*mobile*/
@media (min-width: 768px) {
  .visible-mobile {
    display: none !important;
  }
}
/*desktop*/
@media (max-width: 767.98px) {
  .hidden-mobile {
    display: none !important;
  }
}
h1,
.h1 {
  font-family: "Museo Cyrillic Bold";
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}

h2,
.h2 {
  font-family: "Museo Cyrillic Bold";
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}
@media (max-width: 767.98px) {
  h2,
  .h2 {
    font-size: 32px;
    line-height: 1;
  }
}

h3,
.h3 {
  font-family: "Museo Cyrillic Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
}
@media (max-width: 991.98px) {
  h3,
  .h3 {
    font-size: 28px;
  }
}
@media (max-width: 767.98px) {
  h3,
  .h3 {
    font-size: 16px;
    line-height: 1.5;
  }
}

.bg-yellow {
  background-color: var(--yellowBG);
}

.bg-pink {
  background-color: var(--pinkBG);
}

.bg-blue {
  background-color: var(--blueBG);
}

.bg-green {
  background-color: var(--greenBG);
}

.accent-color {
  background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.accent-italic-color {
  background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic;
}

.blog {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 767.98px) {
  .blog {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.blog__container {
  position: relative;
}
@media (max-width: 767.98px) {
  .blog__container {
    padding: 0px;
  }
}
.blog__body {
  position: relative;
  padding: 80px 70px 50px 48px;
  display: grid;
  grid-template: repeat(2, auto)/37% 1fr 27%;
  grid-template-areas: "blog-left blog-center blog-right" "foot-left foot-center foot-right";
  column-gap: 20px;
  background: var(--grayLight);
}
@media (max-width: 1199.98px) {
  .blog__body {
    grid-template: repeat(3, auto)/repeat(2, auto);
    grid-template-areas: "blog-left blog-right" "foot-left foot-right" "foot-center foot-right";
    column-gap: 40px;
  }
}
@media (max-width: 767.98px) {
  .blog__body {
    display: flex;
    flex-direction: column;
    padding: 0px 15px 64px 15px;
  }
}
.blog__title a {
  border-bottom: 1px solid var(--blackLight);
}
.blog__title a:hover {
  border-color: var(--black);
}
.blog__title a:active {
  color: var(--orange) !important;
  border-color: transparent;
}
.blog__left {
  grid-area: blog-left;
}
.blog__left > * {
  margin-bottom: 48px;
}
@media (max-width: 767.98px) {
  .blog__left > * {
    margin-bottom: 40px;
  }
}
.blog__left > *:not(:first-child) {
  max-width: 372px;
}
@media (max-width: 767.98px) {
  .blog__left > *:not(:first-child) {
    max-width: 100%;
  }
}
.blog__image-left-mobile {
  position: absolute;
  bottom: -30px;
  left: -15px;
}
.blog__center {
  grid-area: blog-center;
  position: absolute;
  top: -120px;
  left: -50px;
  padding-right: 120px;
}
@media (max-width: 1400px) {
  .blog__center {
    top: 0px;
    left: 0px;
    padding-right: 60px;
  }
}
@media (max-width: 1199.98px) {
  .blog__center {
    display: none;
  }
}
.blog__center img {
  width: 100%;
  height: 100%;
}
.blog__center-text {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  width: 144px;
  position: absolute;
  bottom: 40px;
  right: 120px;
}
@media (max-width: 1400px) {
  .blog__center-text {
    bottom: 40px;
    right: 0px;
  }
}
.blog__right {
  grid-area: blog-right;
  position: relative;
}
@media (max-width: 767.98px) {
  .blog__right {
    margin-right: 100px;
  }
}
.blog__image-right-mobile {
  position: absolute;
  bottom: -80px;
  right: -115px;
}
.blog__right > * {
  margin-bottom: 48px;
}
@media (max-width: 767.98px) {
  .blog__right > * {
    margin-bottom: 40px;
  }
}
.blog__text {
  font-family: "Museo Cyrillic Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
  border-bottom: 1px solid var(--blackLight);
}
@media (max-width: 991.98px) {
  .blog__text {
    font-size: 24px;
  }
}
.blog__text:hover {
  border-color: var(--black);
}
.blog__text:active {
  color: var(--orange) !important;
  border-color: transparent;
}
@media (max-width: 767.98px) {
  .blog__text_pl {
    padding-left: 65px;
    position: relative;
  }
}
.blog__foot-left {
  grid-area: foot-left;
  max-width: 320px;
}
@media (max-width: 1199.98px) {
  .blog__foot-left {
    margin-bottom: 48px;
  }
}
@media (max-width: 767.98px) {
  .blog__foot-left {
    margin-bottom: 40px;
    max-width: 100%;
  }
}
.blog__foot-center {
  grid-area: foot-center;
  max-width: 320px;
}
@media (max-width: 767.98px) {
  .blog__foot-center {
    margin-bottom: 40px;
    margin-left: 117px;
    max-width: 100%;
  }
}
.blog__foot-right {
  grid-area: foot-right;
}
@media (max-width: 1199.98px) {
  .blog__foot-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 767.98px) {
  .blog__foot-right {
    justify-content: end;
  }
}
.blog__button {
  display: inline-block;
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--blue);
  border-radius: 50%;
  color: var(--white) !important;
  font-family: "Museo Cyrillic Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
}
@media (max-width: 767.98px) {
  .blog__button {
    width: 200px;
    height: 200px;
    font-size: 24px;
  }
}
.blog__button span {
  border-bottom: 1px solid var(--whiteLight);
}
.blog__button:hover span, .blog__button:focus span {
  border-bottom: 1px solid var(--white);
}
.blog__button:active {
  background-color: var(--orange) !important;
}
.blog__button:active span {
  border-bottom: 1px solid var(--orange);
}
.blog__image-left {
  position: absolute;
  top: 370px;
  left: -160px;
}
.blog__image-bottom {
  position: absolute;
  bottom: 0;
  left: 47%;
}
@media (max-width: 991.98px) {
  .blog__image-bottom {
    left: 35%;
  }
}
.blog__wrapper-image {
  background: var(--grayLight);
}
.blog__image-top-mobile {
  width: 100%;
  margin-bottom: 30px;
}
.blog__image-bottom-mobile {
  position: absolute;
  bottom: -50px;
  left: 0;
}
@media (max-width: 400px) {
  .blog__image-bottom-mobile {
    bottom: 0px;
  }
}

.button {
  display: inline-block;
  padding: 12px 22px;
  color: var(--green);
  font-family: "Museo Cyrillic Bold";
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background-color: var(--white);
  border-radius: 10px;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  border: 2px solid var(--greenLight);
}
.button:hover {
  border-color: var(--green);
  transition: 0.5s;
}
.button:active {
  color: var(--orange);
  border-color: var(--orange);
  transition: 0.5s;
}
.button_green {
  padding: 14px 24px;
  border: none;
  color: var(--white) !important;
  background: linear-gradient(180deg, #30C43F 0%, #2C9A37 100%);
}
.button_green:hover {
  background: linear-gradient(180deg, #30c33f 0%, #30c33f 100%);
  transition: 0.5s;
}
.button_green:active {
  background: linear-gradient(180deg, #FF9F29 0%, #FF9F29 100%);
  transition: 0.5s;
}
.button_blue {
  color: var(--blue);
  border: 2px solid var(--blueLight);
}
.button_blue:hover {
  border-color: var(--blue);
}
.button_blue:active {
  color: var(--orange);
  border-color: var(--orange);
  transition: 0.5s;
}

.catalog {
  margin-bottom: 150px;
}
@media (max-width: 767.98px) {
  .catalog {
    margin-bottom: 48px;
  }
}
.catalog__title {
  margin-bottom: 64px;
}
@media (max-width: 767.98px) {
  .catalog__title {
    margin-bottom: 24px;
  }
}
.catalog__body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;
}
@media (max-width: 1600px) {
  .catalog__body {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .catalog__body {
    gap: 8px;
  }
}
@media (max-width: 767.98px) {
  .catalog_page-catalog .catalog__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
.catalog__item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .catalog__item {
    height: 160px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
}
@media (max-width: 767.98px) {
  .catalog_page-catalog .catalog__item {
    height: 100%;
    text-align: left;
  }
}
.catalog__image {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1100px) {
  .catalog__image {
    opacity: 0.1;
  }
}
@media (max-width: 767.98px) {
  .catalog__image {
    position: relative;
    opacity: 1;
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .catalog_page-catalog .catalog__image {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.catalog__body-item {
  position: relative;
  z-index: 1;
  padding: 48px;
}
@media (max-width: 767.98px) {
  .catalog__body-item {
    padding: 0px;
  }
}
.catalog__body-item_min {
  padding: 30px 48px;
}
@media (max-width: 767.98px) {
  .catalog__body-item_min {
    padding: 0 0 40px;
  }
}
@media (max-width: 767.98px) {
  .catalog_page-catalog .catalog__body-item {
    padding: 40px 16px 56px;
  }
}
@media (max-width: 767.98px) {
  .catalog_page-catalog .catalog__body-item_min {
    padding: 34px 16px;
  }
}
.catalog__body-item > *:not(:last-child) {
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .catalog__body-item > *:not(:last-child) {
    margin-bottom: 32px;
  }
}
@media (max-width: 767.98px) {
  .catalog_page-catalog .catalog__body-item > *:not(:last-child) {
    margin-bottom: 20px;
  }
}
.catalog__link-title {
  padding-bottom: 2px;
  border-bottom: 1px solid var(--blackLight);
}
.catalog__link-title:hover {
  border-color: var(--black);
}
.catalog__link-title:active {
  color: var(--orange);
  border-color: transparent;
}
.catalog__link {
  display: block;
}
@media (max-width: 767.98px) {
  .catalog__link {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .catalog_page-catalog .catalog__link {
    display: block;
  }
}
.catalog__link-text {
  font-family: "Museo Cyrillic Medium";
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--blue) !important;
  border-bottom: 1px solid var(--blueLight);
  margin-right: 8px;
}
@media (max-width: 767.98px) {
  .catalog_page-catalog .catalog__link-text {
    font-family: "Museo Cyrillic Medium";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
}
.catalog__link-text:hover {
  border-color: var(--blue);
}
.catalog__link-text:active {
  color: var(--orange) !important;
  border-color: transparent;
}
.catalog__counter {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--gray);
}
@media (max-width: 767.98px) {
  .catalog_page-catalog .catalog__counter {
    font-size: 10px;
    line-height: 16px;
  }
}

.crumble {
  margin-bottom: 11px;
}
.crumble__list {
  display: flex;
  gap: 7px;
}
.crumble__item {
  display: flex;
  align-items: end;
  gap: 7px;
  color: var(--gray);
}
.crumble__link {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 1px;
  border-bottom: 1px solid var(--blackLight);
}
.crumble__link_active {
  color: var(--black) !important;
  border-bottom: none;
}
.crumble__link:hover {
  border-color: var(--black);
  color: var(--black);
}
.crumble__link:active {
  color: var(--orange);
  border-color: transparent;
}

.footer__container {
  position: relative;
  padding: 80px 72px 100px;
  background-color: var(--black);
  border-radius: 10px;
  color: var(--white);
}
@media (max-width: 1199.98px) {
  .footer__container {
    padding: 50px 50px 150px;
  }
}
@media (max-width: 767.98px) {
  .footer__container {
    padding: 48px 16px 64px;
    border-radius: 0px;
  }
}
.footer__body {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 1199.98px) {
  .footer__body {
    display: grid;
    grid-template: repeat(2, auto)/repeat(3, 1fr);
    grid-template-areas: "section-menu section-second-menu section-about" "section-contacts-m section-contacts-sp section-contacts-sp";
    column-gap: 30px;
    row-gap: 50px;
  }
}
@media (max-width: 767.98px) {
  .footer__body {
    grid-template: repeat(4, auto)/repeat(2, 1fr);
    grid-template-areas: "section-menu section-second-menu" "section-menu section-about" "section-contacts-m section-contacts-m" "section-contacts-sp section-contacts-sp";
    column-gap: 30px;
    row-gap: 30px;
    margin-bottom: 40px;
  }
}
.footer__section-menu {
  grid-area: section-menu;
}
.footer__section-second-menu {
  grid-area: section-second-menu;
}
.footer__section-about {
  grid-area: section-about;
}
.footer__section-contacts-m {
  grid-area: section-contacts-m;
}
@media (max-width: 767.98px) {
  .footer__section-contacts-m {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.footer__section-contacts-sp {
  grid-area: section-contacts-sp;
}
.footer__title {
  font-family: "Museo Cyrillic Bold";
  font-size: 36px;
  line-height: 1.1;
  text-align: left;
  margin-bottom: 16px;
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .footer__title {
    font-family: "Museo Cyrillic Medium";
    font-size: 24px;
  }
}
.footer__contacts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
}
@media (max-width: 767.98px) {
  .footer__contacts {
    font-size: 14px;
  }
}
.footer__contacts_page-menu-mobile {
  gap: 0;
}
.footer__contacts_page-menu-mobile:first-child {
  padding-bottom: 20px;
}
.footer__address {
  font-family: "Inter";
  font-weight: 400;
  line-height: 24px;
}
.footer__wrapper-email {
  font-family: "Museo Cyrillic Medium";
  font-weight: 500;
  line-height: 1.5;
}
.footer__email {
  padding-bottom: 2px;
  color: var(--blue) !important;
  border-bottom: 1px solid var(--blueLight);
}
.footer__email:hover {
  border-bottom: 1px solid var(--blue);
}
.footer__email:active {
  color: var(--orange) !important;
  border-bottom: none;
}
.footer__wrapper-telephone {
  font-family: "Museo Cyrillic Bold";
  font-weight: 700;
  line-height: 1.5;
}
.footer__telephone {
  padding-bottom: 2px;
  color: var(--green) !important;
  border-bottom: 1px solid var(--greenLight);
}
.footer__telephone:hover {
  border-bottom: 1px solid var(--green);
}
.footer__telephone:active {
  color: var(--orange) !important;
  border-bottom: none;
}
.footer__foot {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--whiteLight);
}
@media (max-width: 1199.98px) {
  .footer__foot {
    bottom: 50px;
  }
}
@media (max-width: 767.98px) {
  .footer__foot {
    position: relative;
    bottom: 0;
    text-align: left;
  }
}

.header {
  padding: 16px 0 0 0;
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .header {
    padding: 8px 0 0 0;
    margin-bottom: 0px;
  }
}
.header__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  margin-bottom: 16px;
}
@media (max-width: 991.98px) {
  .header__head {
    display: grid;
    grid-template: repeat(2, 1fr)/repeat(2, 1fr);
    grid-template-areas: "contacts contacts" "second-menu button";
    justify-items: center;
    align-items: center;
  }
}
@media (max-width: 767.98px) {
  .header__head {
    display: flex;
    margin-bottom: 8px;
  }
}
.header__second-menu {
  grid-area: second-menu;
  justify-self: start;
}
.header__contacts {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-area: contacts;
}
.header__contacts_mobile a {
  font-size: 16px;
}
.header__item-contacts {
  display: flex;
  gap: 8px;
  padding: 5px 12px;
}
.header__link-contacts {
  font-family: "Museo Cyrillic Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 143%;
  color: var(--green) !important;
  border-bottom: 1px solid var(--greenLight);
}
.header__link-contacts:hover {
  border-bottom: 1px solid var(--green);
}
.header__link-contacts:active {
  color: var(--orange) !important;
  border-bottom: none;
}
.header__wraper-button {
  grid-area: button;
  justify-self: end;
}
.header__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  margin-bottom: 24px;
}
@media (max-width: 1199.98px) {
  .header__body {
    display: grid;
    grid-template: repeat(2, 1fr)/repeat(2, 1fr);
    grid-template-areas: "logo user-buttons" "search-form search-form";
    gap: 10px;
  }
}
@media (max-width: 767.98px) {
  .header__body {
    display: grid;
    grid-template: repeat(2, 1fr)/repeat(3, 1fr);
    grid-template-areas: "menu-mobile logo basket-mobile" "search-form search-form search-form";
    gap: 8px;
    align-items: center;
  }
}
.header__logo {
  display: flex;
  align-items: center;
  grid-area: logo;
}
@media (max-width: 767.98px) {
  .header__logo {
    min-width: 182px;
  }
}
@media (max-width: 767.98px) {
  .header__logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.header__menu-mobile-open {
  grid-area: menu-mobile;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(225deg, #1f79cb 0%, #1a609f 100%);
  cursor: pointer;
  color: var(--white);
}
.header__menu-mobile-open:active {
  background: var(--blue);
}
.header__basket-mobile {
  grid-area: basket-mobile;
  justify-self: end;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
  color: var(--white);
}
.header__basket-mobile:active {
  background: var(--orange);
}
.header__search-form {
  flex-grow: 1;
  background-color: var(--white2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  border-radius: 10px;
  gap: 24px;
  grid-area: search-form;
}
.header__input-form {
  flex-grow: 1;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.header__input-form::placeholder {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.header__search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header__user-buttons {
  display: flex;
  gap: 32px;
  grid-area: user-buttons;
  justify-self: end;
}
.header__user-button-link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.header__user-button-label {
  position: absolute;
  top: -5px;
  right: 15px;
  width: 28px;
  height: 20px;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--white);
  border-radius: 12px;
  background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
}
.header__user-button-label_mobile {
  top: 8px;
  right: 5px;
  width: 19px;
  height: 12px;
  background: var(--blue);
  font-size: 8px;
  padding: 2px 4px;
  line-height: 8px;
}
.header__user-button-text {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
}
.header__user-button-link:hover .icon_user-button-icon {
  fill: var(--blue);
}
.header__user-button-link:hover .header__user-button-text {
  color: var(--blue);
}
.header__user-button-link:active .icon_user-button-icon {
  fill: var(--orange);
}
.header__user-button-link:active .header__user-button-text {
  color: var(--orange);
}
.header__foot {
  background: var(--blue);
  border-radius: 10px;
  padding: 12px;
}

.icon {
  width: 24px;
  height: 24px;
}
.icon_search {
  width: 20px;
  height: 20px;
  fill: var(--blue);
}
.icon_user-button-icon {
  fill: var(--black);
}
.icon_user-button-icon-mobile {
  fill: var(--white);
}
.icon_menu-mobile-open {
  fill: var(--white);
}
.icon_basket-mobile {
  fill: var(--white);
}
.icon_menu-mobile-close {
  fill: var(--white);
}
.icon_menu-mobile-arrow {
  stroke: var(--white);
  fill: transparent;
  width: 10px;
  height: 6px;
}
.icon_crumble {
  width: 17px;
  height: 8px;
}

.menu__list_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
  font-family: "Museo Cyrillic Bold";
  color: var(--white);
  line-height: 1.5;
}
.menu__list_header .menu__link:hover {
  color: #62a1da;
}
.menu__list_header .menu__link:active {
  color: var(--orange);
}
.menu__list_footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 767.98px) {
  .menu__list_footer {
    font-size: 14px;
    gap: 20px;
  }
}
.menu__list_footer .menu__link {
  padding-bottom: 1px;
  color: var(--white) !important;
  border-bottom: 1px solid var(--whiteLight);
}
.menu__list_footer .menu__link:hover {
  border-bottom: 1px solid var(--white);
}
.menu__list_footer .menu__link:active {
  color: var(--orange) !important;
  border-bottom: none;
}
.menu__list_header .menu__item {
  padding-left: 15px;
  padding-right: 15px;
}
.second-menu__list {
  display: flex;
  gap: 20px;
}
.second-menu__item {
  white-space: nowrap;
}
.second-menu__item a {
  color: var(--gray);
  border-bottom: 1px solid var(--grayLight);
}
.second-menu__item a:hover {
  border-bottom: 1px solid var(--gray);
}
.second-menu__item a:active {
  color: var(--orange);
  border-bottom: none;
}

.page-menu-mobile {
  display: none;
  background: var(--white);
  position: relative;
  padding-bottom: 64px;
}
.page-menu-mobile._menu-mobile-open {
  display: block;
  position: absolute;
  z-index: 3;
  top: 63px;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 132px;
  overflow: scroll;
  cursor: pointer;
}
.page-menu-mobile__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.page-menu-mobile__contacts p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.page-menu-mobile__contacts a {
  font-family: "Museo Cyrillic Bold";
  font-size: 16px;
  line-height: 1.5;
}
.page-menu-mobile__menu-mobile-close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  left: 31px;
  color: var(--white);
  cursor: pointer;
}

.menu-mobile {
  padding: 48px 60px 65px;
  margin-bottom: 24px;
  background: var(--blue);
  color: var(--white);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.menu-mobile__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.menu-mobile__link {
  display: flex;
  align-items: center;
  gap: 4px;
}
.menu-mobile__link._submenu-open + .menu-mobile__submenu {
  display: flex;
}
.menu-mobile__link span {
  border-bottom: 1px solid var(--whiteLight);
}
.menu-mobile__link span:hover {
  border-color: var(--white);
}
.menu-mobile__link span:active {
  color: var(--orange) !important;
  border-color: transparent;
}
.menu-mobile__link:active p {
  color: var(--orange) !important;
}
.menu-mobile__link:active .icon_menu-mobile-arrow {
  stroke: var(--orange);
}
.menu-mobile__submenu {
  display: none;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0 0 16px;
}
.menu-mobile__link-submenu {
  border-bottom: 1px solid var(--whiteLight);
}
.menu-mobile__link-submenu:hover {
  border-color: var(--white);
}
.menu-mobile__link-submenu:active {
  color: var(--orange) !important;
  border-color: transparent;
}
.menu-mobile__user-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.menu-mobile__user-button-link {
  display: flex;
  align-items: center;
  gap: 8px;
}
.menu-mobile__user-button-link:active > * {
  color: var(--orange);
}
.menu-mobile__user-button-link:active .icon_user-button-icon-mobile {
  fill: var(--orange);
}
.menu-mobile__user-button-label {
  position: relative;
}
.menu-mobile__user-button-label span {
  position: absolute;
  top: -20px;
  right: -23px;
  width: 28px;
  height: 20px;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--white);
  border-radius: 12px;
  background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
}

@media (max-width: 767.98px) {
  .news {
    margin-bottom: 64px;
  }
}
.news__title {
  margin-bottom: 32px;
}
.news__link-title {
  font-family: "Museo Cyrillic Bold";
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  color: var(--blue) !important;
  padding-bottom: 1px;
  border-bottom: 1px solid var(--blueLight);
}
@media (max-width: 767.98px) {
  .news__link-title {
    font-size: 32px;
  }
}
.news__link-title:hover {
  border-color: var(--blue);
}
.news__link-title:active {
  color: var(--orange) !important;
  border-color: transparent;
}
.news__body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 48px;
  row-gap: 88px;
}
@media (max-width: 1199.98px) {
  .news__body {
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
  }
}
@media (max-width: 767.98px) {
  .news__body {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    row-gap: 32px;
    margin-bottom: 32px;
  }
}

.news-card__link > * {
  margin-bottom: 12px;
}
@media (max-width: 767.98px) {
  .news-card__link > * {
    margin-bottom: 8px;
  }
}
.news-card__image-block {
  position: relative;
}
.news-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.news-card__coming {
  position: absolute;
  bottom: 4px;
  left: 0;
  padding: 0 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--white);
  background-color: var(--blue);
  border-radius: 0 10px 0 10px;
}
.news-card__attention {
  position: absolute;
  bottom: 4px;
  left: 0;
  padding: 0 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--white);
  background-color: var(--red);
  border-radius: 0 10px 0 10px;
}
.news-card__new {
  position: absolute;
  bottom: 4px;
  left: 0;
  padding: 0 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--white);
  background-color: var(--green);
  border-radius: 0 10px 0 10px;
}
.news-card__contest {
  position: absolute;
  bottom: 4px;
  left: 0;
  padding: 0 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--white);
  background-color: var(--violet);
  border-radius: 0 10px 0 10px;
}
.news-card__title span {
  padding-bottom: 1px;
  border-bottom: 1px solid var(--blackLight);
}
.news-card__title span:hover {
  border-color: var(--black);
}
.news-card__title span:active {
  color: var(--orange) !important;
  border-color: transparent;
}
.news-card__date {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray);
}
@media (max-width: 767.98px) {
  .news-card__date {
    font-size: 14px;
  }
}

.slider {
  display: flex;
  max-width: 100vw;
  padding-bottom: 40px;
  margin-bottom: 100px;
}
@media (max-width: 991.98px) {
  .slider {
    margin-bottom: 50px;
  }
}
.slider__slide {
  display: block;
  border-radius: 10px;
}
.slider__slide img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.slider .swiper-pagination-bullet-active {
  width: 16px;
  height: 8px;
  background: #1f79cb;
  border-radius: 8px;
}