.crumble {
  margin-bottom: 11px;
  &__list {
    display: flex;
    gap: 7px;
  }
  &__item {
    display: flex;
    align-items: end;
    gap: 7px;
    color: var(--gray);
  }

  &__link {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 1px;
    border-bottom: 1px solid var(--blackLight);
  }
  &__link_active {
    color: var(--black) !important;
    border-bottom: none;
  }
  &__link:hover {
    border-color: var(--black);
    color: var(--black);
  }
  &__link:active {
    color: var(--orange);
    border-color: transparent;
  }
}
