.slider {
  display: flex;
  max-width: 100vw;
  padding-bottom: 40px;
  margin-bottom: 100px;

  @media (max-width: 991.98px) {
    margin-bottom: 50px;
  }
  &__wrapper {
  }

  &__slide {
    display: block;
    border-radius: 10px;
  }

  &__slide img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }

  &__swiper-pagination {
  }

  & .swiper-pagination-bullet-active {
    width: 16px;
    height: 8px;
    background: #1f79cb;
    border-radius: 8px;
  }
}

