.menu {
  &__list_header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;
    font-family: "Museo Cyrillic Bold";
    color: var(--white);
    line-height: 1.5;
  }
  &__list_header &__link:hover {
    color: #62a1da;
  }
  &__list_header &__link:active {
    color: var(--orange);
  }

  &__list_footer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    @media (max-width: 767.98px) {
      font-size: 14px;
      gap: 20px;
    }
  }

  &__list_footer &__link {
    padding-bottom: 1px;
    color: var(--white) !important;
    border-bottom: 1px solid var(--whiteLight);
  }

  &__list_footer &__link:hover {
    border-bottom: 1px solid var(--white);
  }
  &__list_footer &__link:active {
    color: var(--orange) !important;
    border-bottom: none;
  }

  &__list_header &__item {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__link {
  }
}

.second-menu {
  &__list {
    display: flex;
    gap: 20px;
  }

  &__item {
    white-space: nowrap;
  }
  &__item a {
    color: var(--gray);
    border-bottom: 1px solid var(--grayLight);
  }
  &__item a:hover {
    border-bottom: 1px solid var(--gray);
  }
  &__item a:active {
    color: var(--orange);
    border-bottom: none;
  }
}

.page-menu-mobile {
  display: none;
  background: var(--white);
  position: relative;
  padding-bottom: 64px;

  &._menu-mobile-open {
    display: block;
    position: absolute;
    z-index: 3;
    top: 63px;
    left: 0;
    width: 100%;
    height: 100%;

    padding-bottom: 132px;
    overflow: scroll;
    cursor: pointer;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__contacts p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  &__contacts a {
    font-family: "Museo Cyrillic Bold";
    font-size: 16px;
    line-height: 1.5;
  }

  &__menu-mobile-close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16px;
    left: 31px;
    color: var(--white);
    cursor: pointer;
  }
}

.menu-mobile {
  padding: 48px 60px 65px;
  margin-bottom: 24px;
  background: var(--blue);
  color: var(--white);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item {
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__link._submenu-open + &__submenu {
    display: flex;
  }

  &__link span {
    border-bottom: 1px solid var(--whiteLight);
  }

  &__link span:hover {
    border-color: var(--white);
  }
  &__link span:active {
    color: var(--orange) !important;
    border-color: transparent;
  }
  &__link:active p {
    color: var(--orange) !important;
  }
  &__link:active .icon_menu-mobile-arrow {
    stroke: var(--orange);
  }

  &__submenu {
    display: none;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0 0 16px;
  }

  &__item-submenu {
  }

  &__link-submenu {
    border-bottom: 1px solid var(--whiteLight);
  }

  &__link-submenu:hover {
    border-color: var(--white);
  }
  &__link-submenu:active {
    color: var(--orange) !important;
    border-color: transparent;
  }

  &__user-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__user-button-link {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__user-button-link:active > * {
    color: var(--orange);
  }
  
  &__user-button-link:active .icon_user-button-icon-mobile {
    fill: var(--orange);
  }
  
  &__user-button-label {
    position: relative;
  }
  &__user-button-label span {
    position: absolute;
    top: -20px;
    right: -23px;
    width: 28px;
    height: 20px;
    padding: 2px 6px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--white);
    border-radius: 12px;
    background: linear-gradient(63.07deg, #ffcb20 0.25%, #ff4b3a 100.64%);
  }
}
